let $ = require('jquery');
let Vivus = require('vivus');

class TagLinkItem {
	constructor($element) {
		this.$element = $element;
		this.$svgElement = this.$element.find('svg');
		if(!this.$svgElement.length) {
			return;
		}
		this.animation = new Vivus(this.$svgElement[0], {
			type: 'delayed',
			duration: 150,
			animTimingFunction: Vivus.EASE_OUT,
			pathTimingFunction: Vivus.EASE_OUT});

		//this.$element.on('mouseenter', e => {
		//	this.animation.stop();
		//	this.animation.reset();
		//	this.animation.play();
		//});
	}

	static createTagLinkItems($elements) {
		$elements.each(function() {
			return new TagLinkItem($(this));
		});
	}
}

module.exports = TagLinkItem;
