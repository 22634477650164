let $ = require('jquery');

class VideoTracking {

	//todo maybe track real duration:
	//http://stackoverflow.com/questions/28751568/youtube-iframe-javascript-track-percentage-of-video-watched

	constructor(config) {
		this.eventActionId = {
			start: 'start',
			start_automatically: 'start_automatically',
			//play_paused: 'play_paused',
			//pause: 'pause',
			close: 'close',
			ended: 'ended',
			viewed_: 'viewed_'
		};
		Object.assign(this, {eventCategory: 'video', eventLabel: ''}, config);
		this.trackPercentages = [25, 50, 75, 95];
		this.eventLabel = 'home teaser video';
	}

	init($videos) {

		$videos.each(((index, element) => {
			let currentMaxViewedStep = 0;
			let $video = $(element);
			let videoLabel = $video.data('label');
			if(!videoLabel) {
				throw new Error('videoTracking - video label is missing, please add a label to the video (attribute data-label to .ivp element)');
				return;
			}
			$video.on('start start_automatically', (e => {
				currentMaxViewedStep = 0;
				this.track(e.type, videoLabel);
			}));

			$video.on('close', (e => {
				this.track(this.eventActionId.close, videoLabel);
			}));
			//$videos.on('play_paused', function() {
			//	this.track(this.eventActionId.play_paused);
			//});

			$video.on('ended', (e => {
				this.track(this.eventActionId.ended, videoLabel);
			}));

			$video.on('timeupdate', (e => {
				let video = e.target;
				let timePercentage = Math.ceil( (100 / video.duration) * video.currentTime);
				let maxTimePercentage = 0;
				for(let i = 0; i < this.trackPercentages.length; i++) {
					if(timePercentage >= this.trackPercentages[i]) {
						maxTimePercentage = this.trackPercentages[i];
					} else {
						break;
					}
				}
				if(maxTimePercentage > currentMaxViewedStep) {
					currentMaxViewedStep = maxTimePercentage;
					this.track(this.eventActionId.viewed_ + currentMaxViewedStep, videoLabel);
				}
			}));

		}));
	}

	track(eventAction, eventLabel) {
		//console.log('track: ' + ', eventCategory: ' + this.eventCategory + ', ' + eventAction + ', ' + eventLabel);
		/*if(ga) {
			ga('send', 'event', this.eventCategory, eventAction, eventLabel);
		}*/
		if(gtag) {
			gtag('event', eventAction, this.eventCategory, eventLabel)
		}
	}
}

module.exports = VideoTracking;
