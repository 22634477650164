let $ = require('jquery');

let AbstractView = function AbstractView(model, $element) {
	this.model = model;
	this.$element = $element;

	$(model).on(model.events.CHANGE, this.render.bind(this));
	this.render();
};

let DigitalView = function DigitalView(model, $element) {
	Object.getPrototypeOf(this).constructor.apply(this, arguments);
};

DigitalView.prototype = Object.create(AbstractView.prototype);

DigitalView.prototype.render = function () {
	this.$element.html(this.model.getHours() + ':' +
		this.format(this.model.getMinutes()) + ':' +
		this.format(this.model.getSeconds()));
};

DigitalView.prototype.format = function (value) {
	return (value < 10 ? '0' + value : value);
};

let DomAnalogView = function DomAnalogView(model, $element) {
	this.$hourHand = $('<div class="hour-hand animated-hand"></div>').appendTo($element);
	this.$minuteHand = $('<div class="minute-hand animated-hand"></div>').appendTo($element);
	this.$secondHand = $('<div class="second-hand animated-hand"></div>').appendTo($element);
	this.seconds = 0;
	this.hours = 0;
	this.minutes = 0;
	Object.getPrototypeOf(this).constructor.apply(this, arguments);
};

DomAnalogView.prototype = Object.create(AbstractView.prototype);

DomAnalogView.prototype.renderHand = function(element, currentVal, targetVal, currentDeg, targetDeg) {
	if (targetVal < currentVal) {
		element.removeClass('animated-hand');
		element.css('transform', 'rotate(' + (currentDeg - 360) + 'deg)');
		let x = element.offset().top; //force browser recalculation/reflow
		element.addClass('animated-hand');
	}
	element.css('transform', 'rotate(' + targetDeg + 'deg)');
};

DomAnalogView.prototype.render = function (e, isClockManipulated, isIncreased) {

	let hourDeg = 60 * this.model.getHours() + this.model.getMinutes();

	// this.renderHand(this.$hourHand, this.hours, this.model.getHours(), this.hours * 360 / 24, this.model.getHours() * 360 / 24);
	this.renderHand(this.$hourHand, this.hours, this.model.getHours(), this.hours * 360 / 24, hourDeg / 2);
	this.renderHand(this.$minuteHand, this.minutes, this.model.getMinutes(), this.minutes * 360 / 60, this.model.getMinutes() * 360 / 60);
	this.renderHand(this.$secondHand, this.seconds, this.model.getSeconds(), this.seconds * 360 / 60, this.model.getSeconds() * 360 / 60);

	this.hours = this.model.getHours();
	this.minutes = this.model.getMinutes();
	this.seconds = this.model.getSeconds();

//		this.$hourHand.css('transform', 'rotate(' + this.model.getHours() * 360 / 24 + 'deg)');
//		this.$minuteHand.css('transform', 'rotate(' + this.model.getMinutes() * 360 / 60 + 'deg)');
	//the problem 359 to 0 = counterclockwise:
	//this.$secondHand.css('transform', 'rotate(' + this.model.getSeconds() * 360 / 60 + 'deg)');
	//solution quick, jump from 360 to 0 without transition, otherwise counterclockwise animation
	//this.$secondHand.toggleClass('animated-hand', this.model.getSeconds() >= this.seconds);
	//this.$secondHand.css('transform', 'rotate(' + this.model.getSeconds() * 360 / 60 + 'deg)');
	//this.seconds = this.model.getSeconds();
	//solution 1, remove transition, set to minus value, add transition again, set to value (timeout needed, otherwise browser will ignore change)
	// if (this.model.getSeconds() < this.seconds) {
	// 	this.$secondHand.removeClass('animated-hand');
	// 	this.$secondHand.css('transform', 'rotate(' + (this.seconds * 360 / 60 - 360) + 'deg)');
	// 	let that = this;
	// 	let x = that.$secondHand[0].offsetTop; //used instead of timeout, force recalculation and reflow of browser
	// 	//setTimeout(function() { //needed
	// 	that.$secondHand.addClass('animated-hand');
	// 	that.$secondHand.css('transform', 'rotate(' + that.model.getSeconds() * 360 / 60 + 'deg)');
	// 	//}, 1);
	// } else { //no longer needed without timeout
	// 	this.$secondHand.css('transform', 'rotate(' + this.model.getSeconds() * 360 / 60 + 'deg)');
	// }
	// this.seconds = this.model.getSeconds();
	//solution 2, always increase angle, but this solution is not really working cause of max int value we will reach! Number.MAX_VALUE
//         let secDeg = this.model.getSeconds() * 360 / 60;
//         if(this.seconds%360 > secDeg%360) {
//             this.seconds += 360;
//         }
//         this.seconds = this.seconds - this.seconds%360 + secDeg;
//         this.$secondHand.css('transform', 'rotate(' + this.seconds + 'deg)');

	//todo javascript only solution, use custom javascript animation, but not jquery: 240 to 0 will rotate backwards
	// this.$secondHand.animate({textIndent: this.model.getSeconds() * 360 / 60}, {
	//     step: function(go) {
	//         $(this).css('-moz-transform','rotate('+go+'deg)');
	//         $(this).css('-webkit-transform','rotate('+go+'deg)');
	//         $(this).css('-o-transform','rotate('+go+'deg)');
	//         $(this).css('transform','rotate('+go+'deg)');
	//     },
	//     duration: 500
	// });
	//solution 4: use javascript custom animation
	//need a clockwise and counterclockwise animation
};

module.exports = {
	DomAnalogView: DomAnalogView,
	DigitalView: DigitalView
};

