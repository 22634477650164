let $ = require('jquery');

//todo not only exclude element-enter-viewport-without-delay
/**
 * animation is only available on desktop
 */
$('.project-timeline section:has(.is-arrow-bottom), project-timeline > .project-timeline-section:has(.is-arrow-bottom), .timeline-line-container:has(.is-arrow-bottom):not(.element-enter-viewport-without-delay)').elementInViewport({
	elementMinAreaTopFactor: 0.5, //orig 0
	elementMinAreaBottomFactor: 0.5, //orig 1
	elementMinAreaTopPixel: 0,
	elementMinAreaBottomPixel: 0, //orig 30
	delay: 100,
	once: false,
	allowResetWhenVisible: false
});

$('.project-timeline section:has(.is-line), .project-timeline > .project-timeline-section:has(.is-line), .timeline-line-container:has(.is-line):not(.element-enter-viewport-without-delay)').elementInViewport({
	elementMinAreaTopFactor: 0,
	elementMinAreaBottomFactor: 1,
	elementMinAreaBottomPixel: 30,
	delay: 100,
	once: true
});

$('.project-timeline section:has(.is-arrow-top), .project-timeline > .project-timeline-section:has(.is-arrow-top), .timeline-line-container:has(.is-arrow-top):not(.element-enter-viewport-without-delay)').elementInViewport({
	elementMinAreaTopFactor: 0,
	elementMinAreaBottomFactor: 0,
	elementMinAreaBottomPixel: 150,
	delay: 100,
	once: true
});
