let $ = require('jquery');
let regexLanguageInUrl = /^\/?([a-z]{2})[\/|$]/;

let animatedEventName = Modernizr._prefixes.join('animationend');

//desktop

$('.page-header nav ul')
	.on('mouseout', 'li a', e => {
		$(e.currentTarget).addClass('is-mouse-out');
		$(e.currentTarget).off(animatedEventName);
		$(e.currentTarget).on(animatedEventName, function() {
			$(this).removeClass('is-mouse-out');
		});
	}).on('mouseover', 'li a', e => {
		$(e.currentTarget).removeClass('is-mouse-out');
		$(e.currentTarget).off(animatedEventName);
	});


//mobile
if(document.getElementById('buttonBack')) {
	document.getElementById('buttonBack').addEventListener('click', e => {
		if(window.sessionStorage.getItem('sidenav') === 'true') {
			e.preventDefault();
			window.history.go(-1);
		} else {} //otherwise fall back to normal button link href (/en or /)
	});
}
if(document.getElementById('buttonSidenav')) {
	document.getElementById('buttonSidenav').addEventListener('click', e => {
		window.sessionStorage.setItem('sidenav', true);
	});
}

function getUrlWithoutLanguage() {
	let path = window.location.pathname;
	path = path.replace(regexLanguageInUrl, ''); //remove language
	path = path.replace(/^\/?/, '');
	if(path.lastIndexOf('/') == path.length -1) {
		path = path.slice(0, -1);
	}
	return path;
}

$('.link-language')
	.on('click', function(e) {
		e.preventDefault();
		let url = getUrlWithoutLanguage();
		if(url == 'contact' && $('#buttonBack').is(':visible')) {
			window.location = $(this).attr('href')
		} else {
			window.location = $(this).attr('href') + getUrlWithoutLanguage();
		}

	});
