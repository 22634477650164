let $ = require('jquery');
let elementInViewport = require('./elementInViewport');

let triggerClasses = require('./falcoColorTrigger');

/* Falco Triggers */


class FalcoColor {

	constructor($element) {
		this.TRIGGERS = {
			scroll: 'scroll',
			click: 'click',
			viewport: 'viewport'
		};
		this.FRICTION_COEFF = 0.03; //.07; // 0…1; The higher, the faster we fade out
		this.SPEED_COEFF = 1; //.5; // 0 < SPEED_COEFF < 1. The higher, the more effect on LOW scrolling speed
		this.animationDelay = 50; //todo find acceptable value

		this.oldSaturation = 0;
		this.$element = $element;
		this.$window = $(window);
		this.animationDelayTimeout = null;
		this.initTriggers();
	}

	initTriggers() {
		this.triggers = [];
		let triggers = this.$element.data('falco-component-triggers');
		triggers = triggers.split(',');
		triggers.forEach(trigger => {
			trigger = trigger.substr(0, 1).toUpperCase() + trigger.substr(1);
			if(triggerClasses[trigger]) {
				this.addTrigger(new triggerClasses[trigger](this));
			}
		});
	}

	update(factor) {
		if(factor) {
			//console.log(' *** ');
			//console.log('factor: ' + factor);
		}
		//console.log('factor: ' + factor);
		let saturation = this.oldSaturation * (1 - this.FRICTION_COEFF);
		if (isNaN(factor))
			factor = 0;
		factor = factor*15;
		saturation = Math.max(
			1 - Math.pow((Math.max(factor + 1, 1)), -this.SPEED_COEFF), // must tune this
			saturation);
		//saturation = Math.max(factor, saturation);

		//console.log('factor: ' + factor, ', old saturation: ' + this.oldSaturation + ', saturation: ' + saturation);
		this.oldSaturation = saturation;
		//console.log("saturation: " + saturation);

		var that = this;
		window.requestAnimationFrame(()=> {

			if(that.animationDelayTimeout) {
				clearInterval(that.animationDelayTimeout);
			}
			that.render();
			if(that.oldSaturation > 0.009) { //todo find right end value
				that.animationDelayTimeout = setTimeout(function() {
					that.update(0);
				}, that.animationDelay);
			} else {
				that.oldSaturation = 0;
				that.render();
			}
		})
	}

	render() {
		this.updateColors(this.oldSaturation);
		this.updateElements(this.oldSaturation);
	}



	updateColors(saturation) {
		$('.falco-def').each((i, e) => {
			let $element = $(e);
			let data = $element.data();
			let keys = Object.keys(data).filter((element, index, array) => {
				return element.match(/falcoDef*/);
			});
			keys.forEach((key) => {
				if(key.indexOf('falcoDef-') == 0) {
					let dataColor = $element.data(key);
					if(dataColor) {
						let color = this.convertHexStringToColor(dataColor);
						$element.data('falcoCalculated-' + key.substr(9), this.convertColorToRgb(this.makeBlack(color, saturation)));
					} else {
						//cleanup element
						$element.removeAttr(key);
					}
				}
			});
		});
	}

	updateElements(saturation) {
		//this.$element.addBack().find("[class*='falco-set']").each((i, e) => {
		this.$element.find("[class*='falco-set']").add(this.$element.filter("[class*='falco-set']")).each((i, e) => {
			let $element = $(e);
			let classNames = $element.attr('class').split(' '); //using attr('class') instead of classNames cause of svg
			classNames = classNames.filter((element, index, array) => {
				return element.match(/falco-set--*/);
			});
			classNames.forEach((className) => {
				let {cssProperty, elementName} = this.parseSetColorClassname(className);
				let dataKey = null;
				let $colorDefinitionElement = null;

				if(cssProperty == 'opacity') {
					$element.css('opacity', saturation);
				} else {
					if(elementName) { //search for color definition of element
						dataKey = cssProperty + '--' + elementName;
						$colorDefinitionElement = $element.closest('[data-falco-def--' + dataKey + ']');
					}
					if(!$colorDefinitionElement || ($colorDefinitionElement && $colorDefinitionElement.length == 0)) {
						dataKey = cssProperty;
						$colorDefinitionElement = $element.closest('[data-falco-def--' + dataKey + ']');
					}
					if(!$colorDefinitionElement || ($colorDefinitionElement && $colorDefinitionElement.length == 0)) {
						dataKey = 'color';
						$colorDefinitionElement = $element.closest('[data-falco-def--' + dataKey + ']');
					}

					let colorCalculated;
					if($element.hasClass('falco-is-read-element-color')) {
						let color = $element.data('falco-def-original--' + cssProperty);
						if(!color) {
							color = $element.css(cssProperty);
							$element.data('falco-def-original--' + cssProperty, color);
						}
						//console.log(this.convertRgbaStringToColor(color));
						//console.log(this.convertHexStringToColor($colorDefinitionElement.data('falco-def--' + dataKey)));
						colorCalculated = this.convertColorToRgb(this.mixColors(this.convertRgbaStringToColor(color), this.convertHexStringToColor($colorDefinitionElement.data('falco-def--' + dataKey)), saturation));
					} else {
						colorCalculated = $colorDefinitionElement.data('falco-calculated--' + dataKey);
					}
					if(colorCalculated) {
						if(elementName) {
							$element.find(elementName).css(cssProperty, colorCalculated);
						} else {
							$element.css(cssProperty, colorCalculated);
						}
					}
				}
			});
		});
	}

	/**
	 *
	 * @param triggerType
	 * @param triggerFunction returns {type: "click", factor: 0}
	 */
	addTrigger(triggerObject) {
		this.triggers.push(triggerObject);
	}

	/**
	 *
	 * @param c
	 * @param saturation 1 means orig color, 0 is black
	 * @returns {Array}
	 */
	makeBlack(c, saturation) {
		var ret = [];
		for (var i = 0; i < c.length; i++)
			ret[i] = Math.round(c[i] * saturation);
		return ret;
	}

	/**
	 * rgbA and rgbB are arrays, amountToMix ranges from 0.0 to 1.0
	 */
	mixColors(colorBase, mixColor, amountToMix) {
		var r = this.mixColorChannel(colorBase[0],mixColor[0],amountToMix);
		var g = this.mixColorChannel(colorBase[1],mixColor[1],amountToMix);
		var b = this.mixColorChannel(colorBase[2],mixColor[2],amountToMix);
		return [r,g,b];
	}

	mixColorChannel(colorBase, mixColor, amountToMix) {
		var channelA = mixColor*amountToMix;
		var channelB = colorBase*(1-amountToMix);
		return parseInt(channelA+channelB);
	}

	convertHexStringToColor(hexColor) {
		return [parseInt(hexColor.slice(1, 3), 16), parseInt(hexColor.slice(3, 5), 16), parseInt(hexColor.slice(5, 7), 16)];
	}
	convertRgbaStringToColor(rgbaColor) {
		rgbaColor = rgbaColor.slice(rgbaColor.indexOf('(') + 1, rgbaColor.indexOf(')'));
		rgbaColor = rgbaColor.replace(/\s/g,'');
		return rgbaColor.split(',');
	}

	convertColorToRgb(color) {
		return 'rgb(' + color.join(',') + ')';
	}

	parseSetColorClassname(className) {
		let parts = className.split('--');
		let object = {cssProperty: parts[1]}; //elementName}className.split('--').splice(1);
		if(parts.length > 2) {
			object['elementName'] = parts[2];
		}
		return object;
	}
}

$.fn.falcoColor = function(config) {
	this.each(function() {
		//this == dom element
		let newConfig = Object.assign({$element: $(this)}, $.fn.falcoColor.DEFAULT_CONFIG, config);
		$(this).data('falcoColor', new FalcoColor($(this), newConfig));

	});
	return this; //allow chaining
};

$.fn.falcoColor.DEFAULT_CONFIG = {
};

$('.falco-component').falcoColor();
