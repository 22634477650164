let $ = require('jquery');

const DEFAULT_CONFIG = {
	once: false,
	delay: 300,
	allowResetWhenVisible: true, //if reset of element is allowed when it should get invisible, but still is in viewport

	allowTriggerOnLoad: true,
	viewportOffsetTopFactor: 0,
	viewportOffsetBottomFactor: 0,

	viewportOffsetTopPixel: 0,
	viewportOffsetBottomPixel: 0,

	//if elementMinAreaTopFactor and elementMinAreaBottomFactor are set to 0.5 element becomes active when 50% is visible
	elementMinAreaTopFactor: 1, //percentage 0..1 or pixel; how much of element must be inside viewport area on top
	elementMinAreaBottomFactor: 1, //percentage 0..1 or pixel; how much of element must be inside viewport area on bottom
	elementMinAreaTopPixel: 0,
	elementMinAreaBottomPixel: 0
};

/**
 * Detects if element is in view
 *
 */

class ElementInViewport {
	constructor($element, config) {
		this.isDebugMode = false;
		if(!$element.length) {
			return;
		}
		this.id = Math.random()*Date.now();
		Object.assign(this, DEFAULT_CONFIG, config);
		this.$window = $(window);
		this.$element = $element;
		if(this.isDebugMode) {
			this.$element.css({
				border: "5px dotted red"
			});
		}
		this.windowHeight = 0;
		this.isInViewport = false;

		this.$window.on('resize.elementInViewport' + this.id, this._onResize.bind(this));
		this.$window.on('load.elementInViewport' + this.id, () => {
			if(this.allowTriggerOnLoad) {
				this._onResize.bind(this)
			}
		});
		this.$window.on('scroll.elementInViewport' + this.id, e => {//todo: one static scroll and resize callback
			this._delayedUpdate();
		});

		//setTimeout(function () {
		this._updateSizes();
		if(this.allowTriggerOnLoad) {
			this._update();
		}
		//}.bind(this), 300);
	}

	_delayedUpdate() {
		if (this.scrollTimeout) {
			clearTimeout(this.scrollTimeout);
		}
		this.scrollTimeout = setTimeout(e => {
			this._update();
		}, this.delay);
	}

	_destroy() {
		this.$window.off('resize.elementInViewport' + this.id);
		this.$window.off('load.elementInViewport' + this.id);
		this.$window.off('scroll.elementInViewport' + this.id);
		if(this.scrollTimeout) {
			clearTimeout(this.scrollTimeout);
			this.scrollTimeout = null;
		}
	}

	_update() {
		let windowScrollTop = this.$window.scrollTop();
		let viewportTop = windowScrollTop + this.windowHeight*this.viewportOffsetTopFactor + this.viewportOffsetTopPixel; // + this.windowHeight*this.viewportOffsetTopFactor ; //this.viewportOffsetBottomPixel gets appended to top
		let viewportBottom = windowScrollTop + this.windowHeight - this.windowHeight*this.viewportOffsetBottomFactor - this.viewportOffsetBottomPixel; //this.viewportOffsetBottomPixel gets appended to bottom

		let elementOffsetTop = this.$element.offset().top;

		let minElementPosTop = viewportTop - this.elementHeight + this.elementHeight*this.elementMinAreaTopFactor + this.elementMinAreaTopPixel;
		//let minElementPosTop = viewportTop - this.elementHeight*this.elementMinAreaTopFactor + this.elementMinAreaTopPixel;
		let maxElementPosBottom = viewportBottom - this.elementHeight*this.elementMinAreaBottomFactor - this.elementMinAreaBottomPixel;

		if(elementOffsetTop >= minElementPosTop && elementOffsetTop <= maxElementPosBottom) {
			if(!this.isInViewport) {
				this.isInViewport = true;
				this.$element.addClass('is-in-viewport');
				this.$element.trigger('enterviewport');
			}
			this.$element.trigger('isinviewport');
			if(this.once) {
				this._destroy();
			}
		} else {
			if((!this.allowResetWhenVisible && (elementOffsetTop + this.elementHeight <= windowScrollTop || elementOffsetTop >= windowScrollTop + this.windowHeight))
				|| this.allowResetWhenVisible) {
				if(this.isInViewport) {
					this.isInViewport = false;
					this.$element.removeClass('is-in-viewport');
					this.$element.trigger('leaveviewport');
				}
				this.$element.trigger('isnotinviewport');
			}

		}
	}

	_updateSizes() {
		this.windowHeight = this.$window.height();
		this.elementHeight = this.$element.outerHeight();
	}

	_onResize() {
		this._updateSizes();
		this._delayedUpdate();
	}
}

$.fn.elementInViewport = function(config) {
	this.each(function() {
		//this == dom element
		let $element = $(this);
		if($element.data('elementInViewport')) {
			throw new Error('ElementInViewport: You are trying to register jQuery component more than once on element.');
		}
		let newConfig = Object.assign({$element: $element}, DEFAULT_CONFIG, config);

		if(!isNaN($element.data('elementMinAreaTopFactor'))) {
			newConfig.elementMinAreaTopFactor = $element.data('elementMinAreaTopFactor');
		}
		if(!isNaN($element.data('elementMinAreaBottomFactor'))) {
			newConfig.elementMinAreaBottomFactor = $element.data('elementMinAreaBottomFactor');
		}
		if(!isNaN($element.data('delay'))) {
			newConfig.delay = $element.data('delay');
		}
		if($element.data('once') !== undefined) {
			newConfig.once = $element.data('once');
		}
		if($element.data('allowTriggerOnLoad') !== undefined) {
			newConfig.allowTriggerOnLoad = $element.data('allowTriggerOnLoad');
		}
		$(this).data('elementInViewport', new ElementInViewport($(this), newConfig));
	});
	return this; //allow chaining
};

module.exports = {
	ElementInViewport: ElementInViewport
};

//$.fn.elementInViewport.DEFAULT_CONFIG = DEFAULT_CONFIG;

