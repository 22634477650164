let $ = require('jquery');

//currently not used
$('.video-loop-delayed').each((index, element) => {
	let $element = $(element);
	let loopDelay = $(element).data('loop-delay');
	if(!isNaN(loopDelay)) {
		$element.addEventListener('ended', function() {
			setTimeout(function() {
				$element.find('video')[0].play();
			}, 5000);
		});
	}
});
