let $ = require('jquery');

$('.component-night-mode').each(function() {

	let $this = $(this),
		$body = $('html'),
		$imageContainer = $this.find('.component-night-mode-image-container.element-in-viewport');
	let isActive = false;
	let isSetInactiveBlocked = false;
	let isWaitingForSetInactive = false;

	let timeoutShowNightModeOn = null,
		timeoutBlockHideNighMode = null;

	$imageContainer.on('isinviewport', e => {
		startAnimation();
	});

	$imageContainer.on('isnotinviewport', e => {
		stopAnimation();
	});

	$this.find('button').on('click', e => {
		toggleAnimation();
		e.preventDefault();
	});

	$(window).on('keydown', e => {
		let key = e.keyCode;
		switch(key) {
			case 27: //escape
				stopAnimation();
				e.preventDefault();
				break;
		}
	});

	function resetAnimation() {
		clearTimeout(timeoutShowNightModeOn);
		clearTimeout(timeoutBlockHideNighMode);
		timeoutShowNightModeOn = null;
		timeoutBlockHideNighMode = null;
		isWaitingForSetInactive = false;
		isActive = false;
	}

	function startAnimation(isForceAnimation) {
		if(!isActive) {
			resetAnimation();
			isActive = true;
			isSetInactiveBlocked = true;
			$body.removeClass('is-night-mode-on');
			$body.addClass('is-night-mode');
			timeoutShowNightModeOn = setTimeout(e => {
				$body.addClass('is-night-mode-on');
			}, 1000);
			timeoutBlockHideNighMode = setTimeout(e => {
				isSetInactiveBlocked = false;
				if(isWaitingForSetInactive) {
					stopAnimation();
				}
			}, 0);
		}
	}

	function stopAnimation(isForceAnimation) {
		if(isActive) {
			if(!isForceAnimation && isSetInactiveBlocked) {
				isWaitingForSetInactive = true;
			} else {
				resetAnimation();
				$body.removeClass('is-night-mode');
			}
		}
	}

	function toggleAnimation() {
		if(isActive) {
			stopAnimation(true);
		} else {
			startAnimation(true);
		}
	}


});

