let $ = require('jquery');
let timeViews = require('./timeViews.js');
let timeModel = require('./timeModel.js');

if($("#domAnalogClockView").length) {
	let model = Object.create(timeModel.TimeModel);
	model.init();
	let controller = new timeModel.TimeController(model);

	window.test = controller;

	//let digitalClock = new timeViews.DigitalView(model, $("#digitalClockView"));
	let domAnalogClock = new timeViews.DomAnalogView(model, $("#domAnalogClockView"));

	//$('.incTimezone').on('click', controller.incTimezoneOffset);
	//$('.decTimezone').on('click', controller.decTimezoneOffset);
}



