let $ = require('jquery');

class FixedFooter {
	constructor($element) {
		this.isFixedDisabled = this._isDisabledBrowser();
		this.$element = $element;
		this.height = $element.height();
		this.$window = $(window);
		this.footerOffsetTop = 0; //175
		this.$document = $(document);
		this.$body = $('body');
		this.isScrollable = false;
		this.delay = 50;

		this.$window.on('resize.elementInViewport' + this.id, this._onResize.bind(this));
		this.$window.on('load.elementInViewport' + this.id, this._onResize.bind(this));
		this.$window.on('scroll.elementInViewport' + this.id, e => {//todo: one static scroll and resize callback
			this._waitForUpdate();
		});
	}
	_isDisabledBrowser() {
		return document.documentMode || /Edge/.test(navigator.userAgent);
	}
	_waitForUpdate() {
		if (this.scrollTimeout) {
			clearTimeout(this.scrollTimeout);
		}
		this.scrollTimeout = setTimeout(e => {
			this._update();
		}, this.delay);
	}
	_onResize() {
		this._updateSizes();
		this._waitForUpdate();
	}
	_updateSizes() {
		this.windowHeight = this.$window.height();
		this.documentHeight = this.$document.height();
		this._updateElementSizes();
	}
	_reset() {
		if(this.resetTextAnimationTimeout) {
			clearTimeout(this.resetTextAnimationTimeout);
		}
	}
	_updateElementSizes() {
		this.$element.css('height', ''); //reset height to can read css it again
		this.elementHeight = parseInt(this.$element.outerHeight());
		this.elementMinHeight = parseInt(this.$element.css('minHeight'));

		this._reset();

		//if element is bigger then window height, set height to element min height
		if(!this.isFixedDisabled && this.elementHeight > this.windowHeight - this.footerOffsetTop) {
			this.elementHeight = this.windowHeight - this.footerOffsetTop;
			if(this.elementHeight < this.elementMinHeight) {
				this.elementHeight = this.elementMinHeight;
			}
			this.$element.css('height', this.elementHeight);
			this.$body.css('margin-bottom', this.elementHeight);
		}
		//check if element can be scrolled on screen
		if(this.isFixedDisabled || this.elementHeight > this.windowHeight - this.footerOffsetTop) {
			if(this.isScrollable) { //deactivate scrollable
				this.isScrollable = false;
				this.$body.removeClass('is-fixed-footer');
				this.$element.css('visibility', 'visible');
			}
			this.$body.css('margin-bottom', '');
		} else {
			if(!this.isScrollable) { //activate scrollable
				this.isScrollable = true;
				this.$body.addClass('is-fixed-footer');
			}
		}

	}
	_update() {
		this._reset();
		let windowScrollTop = this.$window.scrollTop();

		//performance optimization for microsoft
		/*if(this.isScrollable) {
			if(this.documentHeight - this.windowHeight <= windowScrollTop + this.elementHeight*3) { //performance optimication
				this.$element.css('visibility', 'visible');
			} else {
				this.$element.css('visibility', 'hidden');
			}
		}*/

		//active and deactive footer

		if(this.documentHeight - this.elementHeight - this.windowHeight >= windowScrollTop) {
			this.$element.removeClass('is-focus');
		} else {
			this.$element.addClass('is-focus');
		}

		if(this.documentHeight - this.windowHeight <= windowScrollTop + this.elementHeight/2) {
			this.$element.addClass('is-active');
		} else if(this.documentHeight - this.elementHeight - this.windowHeight >= windowScrollTop) {
			this.$element.removeClass('is-active');
		}

		//hide text when half of element is no longer visible
		if(this.documentHeight - this.windowHeight >= windowScrollTop + this.elementHeight/2) {
			this.$element.addClass('is-not-readable');
			this.resetTextAnimationTimeout = setTimeout(function() {
				this.$element.find('.text-animation-1, .text-animation-2, .text-animation-3').css('animation', 'none');
			}.bind(this), 200);
		} else {
			this.$element.removeClass('is-not-readable');
			this.$element.find('.text-animation-1, .text-animation-2, .text-animation-3').css('animation', '');
		}
	}
}

$.fn.fixedFooter = function() {
	this.each(function() {
		new FixedFooter($(this));
	});
};

