let $ = require('jquery');
let Modernizr = (typeof window !== "undefined" ? window['Modernizr'] : typeof global !== "undefined" ? global['Modernizr'] : null);
let ObjectFit = require('./objectFit');
let smoothScroller = require('./smoothScroller');

$.fn.illuVideoPlayer = function(config) {

	class IlluVideoPlayer {

		constructor($element, config) {
			this.events = {
				play: 'play',
				play_paused: 'play_paused',
				pause: 'pause'
			};

			this.$element = $element;
			//this.$wrapper = $element.find('ivp-wrapper');

			this.label = this.$element.data('label');
			this.video = this.$element.find('.ivp-video')[0];
			this.isOnlyLoopVideo = this.video == null;
			this.introVideo = this.$element.find('.ivp-intro-video')[0];
			if(this.introVideo) {
				this.introVideo.muted = true; //todo test
			}
			if(this.isOnlyLoopVideo) {
				this._initObjectFitIntroPolyfill();
				return;
			}
			this._init();
		}

		start(e, dontPushState) {
			this.isIntroInactive = true;
			this._isVideoStarting = true;
			this.$element.addClass('is-intro-inactive');
			this.$element.addClass('is-intro-animated');
			if(this.introVideo) {
				this.introVideo.pause();
			}
			IlluVideoPlayer.setCurrentVideo(this);
			this._initObjectFitVideo();
			this.objectFitVideo.update(1000);
			this.ignoreNextVideoPlayEvent = true;
			//this.video.currentTime = 0;
			this.video.load(); //we need to do this, cause there is a bug in chrome: and setting currentTime doesn't work
			// see: https://code.google.com/p/chromium/issues/detail?id=234779
			this.video.play();

			this._addFocus();
			clearTimeout(this._startingRemoveFocusTimeout);
			this._startingRemoveFocusTimeout = setTimeout(e => {
				this._isVideoStarting = false;
				this._removeFocus();
			}, 5000);

			clearTimeout(this._startingAnimationDoneTimeout);
			this._startingAnimationDoneTimeout = setTimeout(e => {
				this.$element.removeClass('is-intro-animated');
				if(!dontPushState && this.label) {
					//(this is a chrome browser bug - 10 2015:) when state changes or state is pushed,
					//chrome browser currently tries to scroll to last scroll position, we don't wan't this cause we animate it ourself
					//thus wait 1 sek. When navigation back and forward thus the scroll position may be overwritten by browser
					this.isHistoryStatePushed = true;
					window.history.pushState({video: this.label}, '', ''); //delay push state until animation is done
				}
			}, 1000);
			this._positionVideo();
			this.$element.trigger('start');
			if(e) {
				e.preventDefault();
			}
		}

		close(e, dontPushState) {
			if(!this.isIntroInactive) {
				return;
			}
			this.isIntroInactive = false;
			if(!this.video.paused) {
				this.video.pause();
			}
			if(this.introVideo && this.introVideo.currentTime) {
				this.introVideo.currentTime = 0; //todo test if ready is needed
				this.introVideo.play();
			}
			this.objectFitVideo.destroy();
			this.$element.addClass('is-intro-animated');
			this.$element.removeClass('is-intro-inactive');
			if(this.video.currentTime) {
				this.video.currentTime = 0;
				this.video.load(); //needed to show preview image of video again, if video is already loaded, it will be loaded from cache
			}
			if(!dontPushState && this.isHistoryStatePushed && this.label) {
				window.history.pushState({video: ''}, '', '');
			}
			this.isHistoryStatePushed = false;
			this.objectFitIntroPolyfill.update(1000);

			clearTimeout(this._closingAnimationTimeout);
			this._closingAnimationTimeout = setTimeout(e => {
				this.$element.removeClass('is-intro-animated');
			}, 1000);

			if(Math.abs(this.$element.offset().top - $(window).scrollTop()) < 15) {
				smoothScroller.scrollTo(this.$element.offset().top - 50);
			}
			this.$element.trigger('close');
			if(e) {
				e.preventDefault();
			}
		}

		toggleMuted(e) {
			this.video.muted = (!this.video.muted);
			e.preventDefault();
		}

		toggleFullscreen(e) {
			let fullscreenFunction = Modernizr.prefixed('requestFullScreen', this.video) || Modernizr.prefixed('requestFullscreen', this.video);
			if (fullscreenFunction) {
				fullscreenFunction();
			}
			e.preventDefault();
		}

		togglePlay(e) {
			if(this.video.paused) {
				this.video.play();
				this.$element.trigger('play');
			} else {
				this.video.pause();
				this.$element.trigger('pause');
			}
			e.preventDefault();
		}

		resizeVideo() {
			if(!this.video.paused && this.currentOrientation != window.orientation) {
				this._positionVideo();
			}
			this.currentOrientation = window.orientation;
		}

			//private functions

		_init() {
			this.isMouseOver = false;
			this.isIntroInactive = false;
			this._initObjectFitIntroPolyfill();
			this._updatePlayPauseState();
			this._updateMuteState();
			this._addKeyboardListener();
			this._addVideoListener();

			$(this.video).on('play pause', this._updatePlayPauseState.bind(this));
			$(this.video).on('volumechange', this._updateMuteState.bind(this));

			//bind listeners
			this.$element.find('.ivp-intro-button').on('click touchstart', this.start.bind(this));

			//$(window).on('popstate.ivp', this._onPopState.bind(this));

			$(window).on('load', e => { //needed to do this cause safari (2016 03) triggeres a popstate event on load, which causes the video component to close the playing video!
				setTimeout(e => {
					$(window).on('popstate.ivp', this._onPopState.bind(this));
				}, 0);
			});
			this.__addFocusListener();
			this.$element.find('.ivp-button-close').on('click touchstart', this.close.bind(this));
			this.$element.find('.ivp-button-play').on('click touchstart', this.togglePlay.bind(this));

			//https://github.com/videojs/video.js/blob/master/src/js/tech/html5.js
			if(this.isFullscreenable()) {
				this.$element.find('.ivp-button-fullscreen').on('click touchstart', this.toggleFullscreen.bind(this));
			} else {
				this.$element.find('.ivp-button-fullscreen').hide();
			}

			if(this.isVolumneable()) {
				this.$element.find('.ivp-button-mute').on('click touchstart', this.toggleMuted.bind(this));
			} else {
				this.$element.find('.ivp-button-mute').hide();
			}
		}

		_updatePlayPauseState() {
			if(this.video.paused) { //todo cleanup
				this.$element.addClass('is-paused');
				this.$element.removeClass('is-playing');
				let $iconPlay = this.$element.find('.ivp-button-play').find('.ivp-icon-play');
				$iconPlay.prependTo(this.$element.find('.ivp-button-play'));
				if(this.$element.find('.ivp-icon-play animate')[0].beginElement) {
					this.$element.find('.ivp-icon-play animate')[0].beginElement();
				}
			} else {
				this.$element.addClass('is-playing');
				this.$element.removeClass('is-paused');
				let $iconPause = this.$element.find('.ivp-button-play').find('.ivp-icon-pause');
				$iconPause.prependTo(this.$element.find('.ivp-button-play'));
				if(this.$element.find('.ivp-icon-pause animate')[0].beginElement) {
					this.$element.find('.ivp-icon-pause animate')[0].beginElement();
				}
			}
		}

		_updateMuteState() {
			if(this.video.muted) {
				this.$element.addClass('is-muted');
				this.$element.removeClass('is-sound');
			} else {
				this.$element.addClass('is-sound');
				this.$element.removeClass('is-muted');
			}
		}

		_addKeyboardListener() {
			$(window).on('keydown', e => {
				let key = e.keyCode;
				switch(key) {
					case 32: //space
						if(this.isIntroInactive) {
							this._addFocus();
							if(this.video.paused) {
								this.video.play();
							} else {
								this.video.pause();
							}
						}
						e.preventDefault();
						break;
					case 27: //escape
						if(this.isIntroInactive) {
							this.close(e);
						}
						e.preventDefault();
						break;
				}
			});
		}

		__addFocusListener() {
			this.$element.on('touchstart', e => {
				this.isMouseOver = true;
				this._addFocus();
			});

			this.$element.on('touchend', e => {
				this.isMouseOver = false;
				this._removeFocus();
			});

			this.$element.on('mousemove', e => {
				this.isMouseOver = true;
				this._addFocus();
			});

			this.$element.on('mouseenter', e => {
				this.isMouseOver = true;
				this._addFocus();
			});

			this.$element.on('mouseleave', e => {
				this.isMouseOver = false;
				this._removeFocus();
			});

			this.$element.find('.ivp-video-controls').on('mouseenter', e => {
				this.isMouseOverControls = true;
			});

			this.$element.find('.ivp-video-controls').on('mouseleave', e => {
				this.isMouseOverControls = false;
			});
		}

		_removeFocus() {
			if(!this.isMouseOverControls && !this._isVideoStarting) {
				this.$element.removeClass('is-focus');
			}
		}

		_addFocus() {
			this.$element.addClass('is-focus');
			clearTimeout(this._removeFocusTimeout);
			this._removeFocusTimeout = setTimeout(e => {
				this._removeFocus();
			}, 1000);
		}

		_initObjectFitIntroPolyfill() {
			this.objectFitIntroPolyfill = new ObjectFit({
				$widthContainer: this.$element,
				$heightContainer: this.$element,
				$video: this.$element.find('.ivp-intro-video'),
				filltype: 'fill',
				isPolyfill: true
			});
		}

		_initObjectFitVideo() {
			this.objectFitVideo = new ObjectFit({
				$widthContainer: this.$element,
				$heightContainer: $(window),
				$video: this.$element.find('.ivp-video'),
				filltype: 'fitHeightContainer',
				isPolyfill: false
			});
			if(window.orientation != undefined) {
				this.currentOrientation = window.orientation;
				$(this.objectFitVideo).on('resize', this.resizeVideo.bind(this)); //todo only when orientation changes

			}
		}

		_positionVideo() {
			//center (if offset isn't more than 50 pixel)
			let windowHeight = $(window).height();
			let videoContainerPosTop = this.$element.offset().top;
			let posTop = videoContainerPosTop - (windowHeight - this.objectFitVideo.currentHeight)/2;
			posTop = Math.max(videoContainerPosTop - 50, posTop);
			smoothScroller.scrollTo(posTop);
		}

		_addVideoListener() {
			$(this.video).on('play', this._playChanged.bind(this));
			$(this.video).on('ended', this._videoEnded.bind(this));
			$(this.video).on('timeupdate', e => {
				this.$element.trigger(e);
			});
		}

		_playChanged() {
			if(this.ignoreNextVideoPlayEvent) {
				this.ignoreNextVideoPlayEvent = false;
				return;
			}
			if(!this.video.paused) {
				this._positionVideo();
			}
		}

		isFullscreenable() {
			return !!(document.fullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled || document.webkitSupportsFullscreen || document.webkitFullscreenEnabled || document.createElement('video').webkitRequestFullScreen);
		}

		isVolumneable() {
			let test = document.createElement('video');
			test.muted = true;
			return (test.muted === true);
		}

		_videoEnded(e) {
			this.$element.trigger('ended');
			this.close(e, false, true);
		}

		_onPopState(e) {
			if(e.originalEvent.state && e.originalEvent.state.video == this.label) {
				this.$element.trigger('start_automatically');
				this.start(e, true);
			} else if(this.isHistoryStatePushed && this.isIntroInactive) {
				this.close(e, true);
			}
			e.preventDefault(); //can't be prevented, but we try to prevent setting scroll position ;)
		}

		static setCurrentVideo(currentVideo) {
			if(currentVideo) {
				if(currentVideo != IlluVideoPlayer.currentVideo && IlluVideoPlayer.currentVideo) {
					IlluVideoPlayer.currentVideo.close();
				}
			}
			if(!currentVideo && IlluVideoPlayer.currentVideo) {
				IlluVideoPlayer.currentVideo.close();
			}
			IlluVideoPlayer.currentVideo = currentVideo;
		}
	}

	this.each(function() {
		//this == dom element
		let newConfig = Object.assign({$element: $(this)}, $.fn.illuVideoPlayer.DEFAULT_CONFIG, config);
		$(this).data('illuVideoPlayer', new IlluVideoPlayer($(this), newConfig));

	});
	return this; //allow chaining
};

$.fn.illuVideoPlayer.DEFAULT_CONFIG = {

};

