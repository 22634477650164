let $ = require('jquery');

let TimeModel = {

	events:{
		CHANGE:'change'
	},

	init:function () {
		setInterval(this.updateTime.bind(this), 1000);
		this.updateTime();
		return this;
	},

	updateTime:function (isClockManipulated, isIncreased) {
		this.currentDate = new Date();
		$(this).trigger(this.events.CHANGE, isClockManipulated, isIncreased);
	},

	getSeconds:function () {
		return this.currentDate.getSeconds();
	},

	getMinutes:function () {
		return this.currentDate.getMinutes();
	},

	getHours:function () {
		return (this.currentDate.getHours() + this._timezoneOffset) % 24;
	},

	setTimezoneOffset:function (value) {
		if (value < -12) {
			value += 24;
		}
		else if (value >= 12) {
			value -= 24;
		}
		let isIncreased = (this._timezoneOffset < value);
		this._timezoneOffset = value;
		this.updateTime(true, isIncreased);
	},
	getTimezoneOffset:function () {
		return this._timezoneOffset;
	},

	// ---- private -----
	_timezoneOffset:0
};

let TimeController = function (model) {
	this.model = model;

//            let that = this;

	this.incTimezoneOffset = function () {
		this.model.setTimezoneOffset(this.model.getTimezoneOffset() + 1);
	}.bind(this);

	this.decTimezoneOffset = function () {
		this.model.setTimezoneOffset(this.model.getTimezoneOffset() - 1);
	}.bind(this);
};

module.exports = {
	TimeModel: TimeModel,
	TimeController: TimeController
};




