let $ = require('jquery');
let Modernizr = (typeof window !== "undefined" ? window['Modernizr'] : typeof global !== "undefined" ? global['Modernizr'] : null);

class ObjectFit {
	constructor(config) {

		config = Object.assign(config, ObjectFit.defaultConfig);

		if(config.filltype == 'fit') {
			this.resizeFunction = this.fit;
		} else if(config && config.filltype == 'fitHeightContainer') {
			this.resizeFunction = this.fitHeightContainer;
		} else if(config && config.filltype == 'fillHeightContainer') {
			this.resizeFunction = this.fillHeightContainer;
		} else {
			this.resizeFunction = this.fill;
		}
		this.$heightContainer = config.$heightContainer;
		this.$widthContainer = config.$widthContainer;
		this.$video = config.$video;
		this.isActive = false;
		this.$window =  $(window);

		if(!config.isPolyfill || !Modernizr.objectfit) {
			this.isActive = true;
			this.resizeFunction();

			this.eventNamespaced = 'resize.objectFit' + Date.now();

			$(window).on(this.eventNamespaced, e => {
				this.resizeFunction();
				$(this).trigger('resize');
			});
		}
	}

	update(updateDuration) {
		if(!this.isActive) {
			return;
		}
		let time = 0;
		let that = this;
		let startTime = new Date().getTime();
		(function animate() {
			window.requestAnimationFrame(e => {
				if(!that.isActive) {
					return;
				}
				setTimeout(function() {
					if(!that.isActive) {
						return;
					}
					that.resizeFunction();
					if(new Date().getTime() - startTime < updateDuration && that.isActive) {
						animate();
					}
				}, 0);
			});
		})();
	}

	fillHeightContainer() {
		//this.$widthContainer.width() / this.$heightContainer.height() > this.videoRatio
		if(9 * this.$widthContainer.width() < 16 * this.$heightContainer.height()) {
			this.$video.css('width', 'auto');
			this.$video.css('height', '100%');
			this.$widthContainer.css('height', Math.ceil(this.$widthContainer.width()/16*9));
		} else {
			this.$widthContainer.css('height', this.$heightContainer.height());
			this.$video.css('width', '100%');
			this.$video.css('height', 'auto');

		}
	}

	fitHeightContainer() {
		//this.$widthContainer.width() / this.$heightContainer.height() > this.videoRatio
		if(9 * this.$widthContainer.width() < 16 * this.$heightContainer.height()) {
			this.$video.css('width', '100%');
			this.$video.css('height', 'auto');

			//if(this.$widthContainer.height < this.$widthContainer.width()/16*9) { //todo
			//	this.$video.css('width', 'auto');
			//	this.$video.css('height', '100%');
			//}
			this.currentHeight = Math.floor(this.$widthContainer.width()/16*9);
			this.$widthContainer.css('height', this.currentHeight);

		} else {
			this.currentHeight = this.$heightContainer.height();
			this.$widthContainer.css('height', this.currentHeight);
			this.$video.css('width', 'auto');
			this.$video.css('height', '100%');
		}
	}

	destroy() {
		if(!this.isActive) {
			return;
		}
		this.isActive = false;
		this.$widthContainer.css('height', '');
		$(window).off(this.eventNamespaced);
	}

	fit() {
		if (9 * this.$widthContainer.width() < 16 * this.$heightContainer.height()) {
			this.$video.css('width', '100%');
			this.$video.css('height', 'auto');
		} else {
			this.$video.css('width', 'auto');
			this.$video.css('height', '100%');
		}
	}

	fill() {
		if (9 * this.$widthContainer.width() > 16 * this.$heightContainer.height()) {
			this.$video.css('width', '100%');
			this.$video.css('height', 'auto');
		} else {
			this.$video.css('width', 'auto');
			this.$video.css('height', '100%');
		}
	}

	static defaultConfig() {
		return {
			filltype: 'fit',
			$heightContainer: $(window),
			$widthContainer: $(window),
			$video: $(video),
			isPolyfill: true
		}
	}
}

module.exports = ObjectFit;
