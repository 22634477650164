require("babel-polyfill"); //we need to add polyfill for ecma6 usage

//https://codepen.io/anon/pen/QyWQjQ
let $ = require('jquery');
//let _ = require('lodash');

require('./components/isAutoplaySupported.js');

require('./components/video.js');
require('./components/videoLoopAutoplayer.js');

require('./components/headernav.js');
require('./components/clipPathTest.js');
let videoTracker = new (require('./components/videoTracking.js'))();

$('.ivp').illuVideoPlayer();
videoTracker.init($('.ivp:has(.ivp-video)'));
$('.video-loop-autoplayer').videoLoopAutoplayer();
require('./components/loadAnimation.js');

require('./components/accordion.js');
require('./components/imageMaxWidthControlled.js');

require('./components/elementInViewport.js');
$('.element-in-viewport').elementInViewport({
	elementMinAreaTopFactor: 1,
	elementMinAreaBottomFactor: 0.5,
	delay: 100,
	once: true
});

$('.element-enter-viewport').elementInViewport({
	elementMinAreaTopFactor: 0,
	elementMinAreaBottomFactor: 0,
	delay: 100,
	once: false
});

$('.element-enter-viewport-without-delay').elementInViewport({
	elementMinAreaTopFactor: 0,
	elementMinAreaBottomFactor: 0,
	delay: 10, //todo find acceptable delay
	once: false
});

require('./components/videoLoopDelayed.js');
//test server google analytics tracking id: UA-67688965-1
//live server google analytics tracking id: UA-67686568-1

//pages
require('./pages/oebbInfoMonitor.js');
require('./components/sayhi.js');
require('./components/workLineAnimated.js');

let TagLinkItem = require('./components/tagLinkItem.js');
new TagLinkItem.createTagLinkItems($('.tag-link-item'));

require('./components/trackingLinkClick.js');

$('.tracking-link-click').trackingLinkClick();
require('./components/fixedFooter.js');
$('.fixed-footer').fixedFooter();
require('./components/hideCurrentYear.js');
require('./components/falcoColor.js');
require('./components/oebb-clock/timeMain.js');
