let $ = require('jquery');
class TrackingLinkClick {
	constructor($element) {
		this.eventAction = 'click';
		let id = $element.data('trackingId');
		let trackingAction = 'click';
		if($element.data('trackingAction')) { //e.g. mousedown
			trackingAction = $element.data('trackingAction');
		}
		$element.on(trackingAction, e => {
			if(id) {
				/*if(window.ga) { //todo if reuse remove fixed homeTag and replace by something like button
					window.ga('send', 'event', 'linkClick', this.eventAction, id);
				}*/
				if(gtag) {
					gtag('event', this.eventAction, 'linkClick', id)
				}
			}
		});
	}
}

$.fn.trackingLinkClick = function() {
	let trackingLinks = [];
	this.each(function() {
		trackingLinks.push(new TrackingLinkClick($(this)));
	});
};
