let $ = require('jquery');

$.fn.videoLoopAutoplayer = function(config) {

	class VideoLoopAutoplayer {

		constructor($element, config) {
			this.$window = $(window);
			this.windowHeight = 0;
			this.$element = $element;
			this.$video = this.$element.find('video');
			this.$video.attr('muted', 'muted');
			this.elementHeight = 0;
			this.elementHeightOverflow = 0;
			this.elementHeightOverflowTolerance = 200;
			this.currentElementHeightOverflowTolerance = 0;
			this.isVideoStartingOnlyWhenFullyVisible = true;
			this.scrollTimeout = null;
			this.isActive = false;
			this.isInitialized = false;
			this.isPlaying = false;

			this.timeOffset = 0;

			if (this.$element.length > 1) {
				console.warn('device showcase dosent support currently more than 1 component on a page');
			}
			this.timeOffset = !isNaN(parseInt(this.$element.data('starttime'))) ? parseInt(this.$element.data('starttime')) : 0;
			$(this.$element.find('video')).on('canplay', e => {
				$(this.$element.find('video')).off('canplay');
				this.$element.find('video')[0].currentTime = this.timeOffset;
			});

			this.$window.on('resize', this._onResize.bind(this));
			this.$window.on('load', this._onResize.bind(this));
			this.$window.on('scroll', e => {
				this._waitForUpdateVideoState();
			});
			this._updateVideoState();
			setTimeout(e => {
				this._updateSizes();
				this.isInitialized = true;
				this._updateVideoState();
			}, 300);
		}

		play() {
			//this.$element.css('border', '1px solid red');
			this.isPlaying = true;
			this.$element.find('video').attr('loop', 'loop');
			this.$element.find('video')[0].play();
		}

		stop() {
			//this.$element.css('border', 'none');
			this.isPlaying = false;
			this.$element.find('video').removeAttr('loop');
			this.$element.find('video')[0].pause();
			if (this.$element.find('video')[0].readyState >= 1) {
				this.$element.find('video')[0].currentTime = this.timeOffset;
			}
		}

		_updateVideoState() {
			this.isActive = this.$video.css('display') != 'none';
			if (!this.isActive || !this.isInitialized) {
				return;
			}

			if ((this.$window.scrollTop() > this.$element.offset().top + this.elementHeight || (this.$window.scrollTop() + this.windowHeight) < this.$element.offset().top)) {
				if (this.isPlaying) {
					this.stop();
				}
			} else if (!this.isPlaying &&
				this.$window.scrollTop() + this.elementHeightOverflow - this.currentElementHeightOverflowTolerance / 2 < this.$element.offset().top &&
				(this.$window.scrollTop() + this.windowHeight - this.elementHeightOverflow + this.currentElementHeightOverflowTolerance / 2) > this.$element.offset().top + this.elementHeight) {
				this.play();
			}
		}

		_waitForUpdateVideoState() {
			if (this.scrollTimeout) {
				clearTimeout(this.scrollTimeout);
			}
			this.scrollTimeout = setTimeout(e => {
				this._updateVideoState();
			}, 300);
		}

		_updateSizes() {
			this.windowHeight = this.$window.height();
			this.elementHeight = this.$element.height();
			this.elementHeightOverflow = parseInt(this.windowHeight) - parseInt(this.$element.find('video').css('height'));
			this.isVideoStartingOnlyWhenFullyVisible = this.elementHeightOverflow > this.elementHeightOverflowTolerance; //bigger than 0 with offset
			if (this.elementHeightOverflow > 0) {
				this.elementHeightOverflow = 0;
			}
			this.currentElementHeightOverflowTolerance = !this.isVideoStartingOnlyWhenFullyVisible ? this.elementHeightOverflowTolerance : 0;
		}

		_onResize() {
			this._updateSizes();
			this._waitForUpdateVideoState();
		}
	}

	this.each(function() {
		//this == dom element
		let newConfig = Object.assign({$element: $(this)}, $.fn.videoLoopAutoplayer.DEFAULT_CONFIG, config);
		$(this).data('videoLoopAutoplayer', new VideoLoopAutoplayer($(this), newConfig));
	});

	return this; //allow chaining
};

$.fn.videoLoopAutoplayer.DEFAULT_CONFIG = {

};

