let $ = require('jquery');

$('.image-max-width-controlled').each(function() {
	let $imageElement = null;
	if($(this)[0].nodeName.toLowerCase() == 'img') {
		$imageElement = $(this);
	} else {
		$imageElement = $(this).find('img');
	}
	if(parseInt($imageElement.attr('width'))) {
		$(this).css('max-width', parseInt($imageElement.attr('width')) + 'px');
	}

});
