let $ = require('jquery');

class FalcoTriggerScroll {
	constructor(component) {
		this.minValue = 0;
		this.maxValue = 9;
		this.type = 'click';
		this.component = component;
		this.$window = $(window);
		this.scrollPos = 0;
		this.$window.on('load', function() {
			setTimeout(function() {
				this.$window.on('scroll', this.scrollHandler.bind(this));
			}.bind(this), 0);
		}.bind(this));
	}
	scrollHandler(e) {
		//clearInterval(intervalId);
		var dy = this.scrollPos - this.$window.scrollTop();
		this.scrollPos = this.$window.scrollTop();
		let scrollFactor = Math.min((Math.abs(dy) - this.minValue)/(this.maxValue - this.minValue), 1);
		this.component.update(scrollFactor);
		//intervalId = setInterval(update, 100);
	}
}

class FalcoTriggerClick {
	constructor(component) {
		this.type = 'click';
		this.component = component;
		this.component.$element.on('click', () => {
			this.component.update(1);
		});
	}
}

class FalcoTriggerElementInViewport {
	constructor(component) {
		this.minValue = 0;
		this.maxValue = 11;
		this.type = 'click';
		this.component = component;
		this.$window = $(window);
		this.scrollPos = 0;
		this.namecpaceId = Math.random()*Date.now();

		this.component.$element.on('enterviewport', function(e) {
			this.scrollHandler(e);
			this.$window.on('scroll.FalcoTriggerElementInViewport' + this.namecpaceId, this.scrollHandler.bind(this));
		}.bind(this));
		this.component.$element.on('leaveviewport', function(e) {
			this.scrollHandler(e);
			this.$window.off('scroll.FalcoTriggerElementInViewport' + this.namecpaceId);
		}.bind(this));

		if(this.component.$element.data('elementInViewport').isInViewport) {
			this.scrollHandler();
			this.$window.on('scroll.FalcoTriggerElementInViewport' + this.namecpaceId, this.scrollHandler.bind(this));
		}
	}
	scrollHandler(e) {
		//clearInterval(intervalId);
		var dy = this.scrollPos - this.$window.scrollTop();
		this.scrollPos = this.$window.scrollTop();
		let scrollFactor = Math.min((Math.abs(dy) - this.minValue)/(this.maxValue - this.minValue), 1);
		this.component.update(scrollFactor);
		//intervalId = setInterval(update, 100);
	}
}

module.exports = {
	Scroll: FalcoTriggerScroll,
	Click: FalcoTriggerClick,
	ElementInViewport: FalcoTriggerElementInViewport
};
