let $ = require('jquery');

/**
 * jquery animation is currently smoother than css animation
 */
class SmoothScroller {
	constructor() {
		this.isTransitioning = false;
	}

	hasFeatureTransition() {
		//return Modernizr.csstransitions;
	}

	scrollTo(scrollTop) {
		let $scrollContainer = $('body');
		let availableHeight = $(document).height() - $(window).height();
		if (availableHeight < scrollTop) {
			scrollTop = availableHeight;
		}
		let currentScrollTop = $(window).scrollTop();
		this.scrollTop = scrollTop;

		let scrollDuration = Math.min(Math.max(Math.abs($(window).scrollTop() - scrollTop)/800, 0.3), 0.6);
		//let transitionedEventName = Modernizr._prefixes.join('transitionend ');
		//$scrollContainer.off(transitionedEventName);
		//$scrollContainer.on(transitionedEventName, function (e) {
		//	if(e.target == e.currentTarget && this.isTransitioning) {
		//		$scrollContainer.off(transitionedEventName);
		//		$(e.currentTarget).removeAttr("style").data("transitioning", false);
		//		this.isTransitioning = false;
		//		$scrollContainer.scrollTop(this.scrollTop);
		//	}
		//}.bind(this));

		//if(this.hasFeatureTransition()) {
		//	$scrollContainer.css({
		//		"transition": scrollDuration + 's ease-in-out',
		//		"margin-top": ( $(window).scrollTop() - scrollTop ) + "px"
		//	});
		//	this.isTransitioning = true;
		//} else {
			$('html, body').animate({
				scrollTop: scrollTop
			}, scrollDuration*1000);
		//}
	}
}

let smoothScroller = new SmoothScroller();

module.exports = smoothScroller;
