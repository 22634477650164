let $ = require('jquery');
let activeElement = null;
const scrollToElementOffsetTop = 30;

function init() {

	$('.accordion').each(function() {

		$(this).on('click', 'li .header', function(e) {
			//close all opened items
			/*if(!$('.accordion .c2.is-active').is($(this).parent())) {
				toggleElement($('.accordion .c2.is-active'));
			}*/
			toggleElement($(this).parent(), true);
		});

		initElements();
	});

	$(window).on('resize', function() {
		$('.accordion li').each(function() {
			updateElementHeight($(this).find('.c2'), false);
		});
	});
}

function initElements() {
	$('.accordion li').each(function() {
		$(this).find('.c2').removeClass('is-active');
		$(this).find('.content').height(0);
	});
}


function toggleElement($element, isAnimated) {
	$element.toggleClass('is-active');
	updateElement($element, isAnimated);
}

function updateElementHeight($element, isAnimated) { //todo
	let isActive = $element.hasClass('is-active');
	let $content = $element.find('.content');
	if(isActive) {
		$content.toggleClass('is-animated', isAnimated);
		$content.css('height', 'auto');
		let height = $content[0].clientHeight;
		$content.height(height);
		$content.addClass('is-animated', isAnimated);
	} else {
		$content.height('0');
	}
}

function updateElement($element, isAnimated) {
	let isActive = $element.hasClass('is-active');
	let $content = $element.find('.content');
	if(isActive) {
		//if(activeElement && activeElement != $element) {
			//activeElement.removeClass('is-active');
			//updateElement(activeElement);
		//}
		$content.css('height', 'auto');
		let height = $content[0].clientHeight;
		$content.css('height', '0');
		//$content.toggleClass('is-animated', true);

		setTimeout(function() {
			$content.height(height);
			$('html, body').stop().animate({
				scrollTop: ($element.offset().top - scrollToElementOffsetTop),
				duration: 300
			}, function() {
			});
		}, 0);
		activeElement = $element;

	} else {
		$content.height('0');
	}
}

init();
